import React, { useEffect, useState } from "react"
import Item from "../components/item"

export default function Items() {
    const [items, setItems] = useState([])

    const fetchItems = async () => {
        const response = await fetch("https://api.scalablefoundations.com/prod/items")
        const data = await response.json()
        setItems(data["items"])
    }

    useEffect(() => {
        console.log('hello world');
        fetchItems()
      }, []);

    return (
      <div className="item">
        <div>Items View</div>
        <p>items: {items.map((item, i) => {
            return <Item name={item.itemName} description={item.itemDesc}></Item>
        })}</p>
        {/*<p>{this.props.description}</p>*/}
      </div>
    );
}