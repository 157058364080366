import React from 'react';

export default class Item extends React.Component {
  render() {
    return (
      <div className="item">
        <h1>Item info for: {this.props.name}</h1>
        <p>Description: {this.props.description}</p>
        {/*<p>{this.props.description}</p>*/}
      </div>
    );
  }
}